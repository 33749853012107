import { NgModule } from '@angular/core';
import { BreadcrumComponent } from './breadcrum.component';
import { CommonModule } from '@angular/common'; 

@NgModule({

 	imports:[
 		CommonModule, 
 	],
	declarations: [BreadcrumComponent ],
	exports: [BreadcrumComponent]
})

export class BreadcrumModule {}